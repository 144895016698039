:root {
  --lavendar: #ece9ff;
  --brown: #231815;
  --red: #e20613;
  --carrot: #ff6f00;
  --salmon: #f29477;
  --orange: #f3981a;
  --mustard: #fbc36b;
  --yellow: #ffda3e;
  --white: #ffffff;
  --black: #000000;
}

@font-face {
  font-family: MagicNeys;
  src: url("../assets/fonts/MagicNeys.otf");
}

@font-face {
  font-family: MagicSound;
  src: url("../assets/fonts/MagicSound.ttf");
}

@font-face {
  font-family: DINPro;
  src: url("../assets/fonts/DINPro.ttf");
}
